.links-height {
    height: 650px;
}

/* 
@media screen and (max-width: 1316px) {
    .links-height {
        position: absolute;
        top: 300px;
        left: 5px;
    }
} */

.close-btn-resume {
    margin-right: 2%;
    font-size: 45px;
    opacity: 1;
}

.close-btn {
    font-size: 34px;
    margin-left: 95%;
}

.skills-close-btn {
    font-size: 34px;
    margin-left: 85%;
    margin-top: 30px;
}

.close-btn:hover,
.skills-close-btn:hover,
.close-btn-resume:hover {
    color: #FF007F;
    opacity: 1 !important;
}

/* About ----------------------------------------- */

.brand-statement {
    margin-right: 40px;
    margin-bottom: 0px;
    line-height: 300%;
    font-size: 20px;
}

/* Resume --------------------------------------- */

.modal-content {
    border-radius: 6px;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
}

.modal-footer {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    -webkit-border-bottom-left-radius: 6px;
    -webkit-border-bottom-right-radius: 6px;
    -moz-border-radius-bottomleft: 6px;
    -moz-border-radius-bottomright: 6px;
    background-color: #02CCFE;
}

.mobile-download {
    margin: 0px;
    background-color: #02CCFE;
}

.mobile-download,
#mobile-download-here {
    color: rgba(0, 0, 0, 0);
}

/* Skills --------------------------------------- */

.cert-paragraph {
    padding: 50px;
    padding-right: 150px;
}

.clickable-skills:hover {
    color: #FF007F;
    cursor: pointer;
}

.certs {
    margin-left: 100px;
}

.clickable-skills-certs {
    height: 100px;
}

.clickable-skills-certs:hover {
    border-bottom: solid #FF007F;
    padding-bottom: 5px;
    cursor: pointer;
}

@media screen and (max-width: 1316px) {

    .links-height {
        margin-top: 130px;
        height: 350px;
    }

    .close-btn {
        margin-bottom: -40px;
        margin-right: 15px;
    }

    .brand-statement {
        margin-right: 5px;
        margin-left: 5px;
        margin-bottom: 0px;
        line-height: 150%;
        font-size: 15px;
    }

    .mobile-download {
        color: black;
        background-color: white;
        margin-top: 50px;
    }

    #mobile-download-here {
        color: #FF007F;
    }

    .certs {
        margin-left: 0px;
    }

    .clickable-skills-certs {
        height: 90px;
        margin-bottom: 10px;
        margin-top: 25px;
    }

    .skills-close-btn {
        margin-top: 60px;
        font-size: 25px;
    }

    .bottom-skill {
        padding-left: 20px;
        padding-top: 12px;
        list-style-type: none;
    }

    .cert-paragraph {
        padding: 15px;
    }

    .back-arrow-all-skills {
        margin-top: 3px;
    }

    .go-back-all-skills:hover {
        color: #FF007F;
    }
}