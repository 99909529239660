.btn:focus, .close-btn-resume:focus {
  outline: 0;
  box-shadow: none;
}

#show {
  display: block;
}

#hide {
  display: none;
}