.side-wrapper {
    /* position: absolute;
    top: 350px;
    left: 80px; */
    margin-top: -80px;
}

/* Cloud ----------------------------------------- */

.cloud {
    /* position: absolute;
    top: -90px;
    left: 5px; */
    margin-top: -60px;
    margin-left: 40px;
    height: 300px;
    width: 500px;
}

#cloudDrawn {
    stroke-dasharray: 5;
}

path.cloudDrawn {
    fill: none;
    stroke: #02CCFE;
    stroke-width: 7;
    stroke-dasharray: 5127;
    stroke-dashoffset: -5127;
    animation: cloud 4s linear forwards;
}

path.cloudDrawnMobile {
    fill: none;
    stroke: #02CCFE;
    /* stroke: pink; */
    stroke-width: 7;
    /* stroke-dasharray: 5127; */
    /* stroke-dashoffset: -5127; */
    /* animation: cloud 4s linear forwards; */
}

@keyframes cloud {
    to {
        stroke-dashoffset: -600;
    }
}

/* Head in the Clouds text ------------------------------------- */
.bouncing-text {
    position: absolute;
    top: 280px;
    display: flex;
    margin-left: 100px;
    font-size: 34px;
    font-family: 'Pacifico';
}

.letter {
    color: #02CCFE;
    text-shadow: 0 2px #2878eb, 0 3px #2878eb, 0 5px #2878eb;
}

.i,
.t,
.c {
    margin-left: 15px;
}

@keyframes bounce {
    0% {
        transform: scale(1, 1) translateY(0);
    }

    10% {
        transform: scale(1.1, .9) translateY(0);
    }

    30% {
        transform: scale(.9, 1.1) translateY(-55px);
    }

    50% {
        transform: scale(1.05, .95) translateY(0);
    }

    58% {
        transform: scale(1, 1) translateY(-7px);
    }

    65% {
        transform: scale(1, 1) translateY(0);
    }

    100% {
        transform: scale(1, 1) translateY(0);
    }
}

.h {
    animation: bounce 1s ease forwards .3s;
}

.e {
    animation: bounce 1s ease forwards .4s;
}

.a {
    animation: bounce 1s ease forwards .5s;
}

.d {
    animation: bounce 1s ease forwards .6s;
}

.i {
    animation: bounce 1s ease forwards .7s;
}

.n {
    animation: bounce 1s ease forwards .8s;
}

.t {
    animation: bounce 1s ease forwards .9s;
}

.hh2 {
    animation: bounce 1s ease forwards 1s;
}

.e2 {
    animation: bounce 1s ease forwards 1.1s;
}

.c {
    animation: bounce 1s ease forwards 1.2s;
}

.l {
    animation: bounce 1s ease forwards 1.3s;
}

.o {
    animation: bounce 1s ease forwards 1.4s;
}

.u {
    animation: bounce 1s ease forwards 1.5s;
}

.d2 {
    animation: bounce 1s ease forwards 1.6s;
}

.s {
    animation: bounce 1s ease forwards 1.7s;
}

/* Raindrops ------------------------------------- */

.raindrops {
    margin: 0 auto;
    display: flex;
    flex-direction: column;
}

#links-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    animation: show-top 5s forwards 0s, pop-in-top 2s ease forwards 3.5s;
}

#links-row button {
    width: 80px;
    height: 80px;
    border-radius: 0 50% 50% 50%;
    border: 3px solid #02CCFE;
    transform: rotate(45deg);
    margin: 10px 10px 10px 30px;
    flex-direction: column;
    justify-content: center;
    color: white !important;
    background-color: #02CCFE;
    padding: 0px;
}

#links-row button:hover {
    background-color: white;
    color: #02CCFE !important;
    cursor: pointer;
}

#links-row button:focus {
    background-color: white;
    color: #02CCFE !important;
    outline: #02CCFE;
}

#links-row p {
    margin-left: 1px;
    font-size: 21px;
    font-weight: 700;
    cursor: pointer;
}

@keyframes pop-in-top {
    from {
        margin-top: -40px;
    }

    to {
        margin-top: 0px;
    }
}

@keyframes show-top {
    0% {
        opacity: 0;
    }

    90% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


@media screen and (max-width: 1316px) {
    .cloud {
        height: 100px;
        width: 300px;
    }

    .bouncing-text {
        position: absolute;
        top: 145px;
        display: flex;
        margin-left: 60px;
        font-size: 18px;
    }

    #links-row button {
        width: 60px;
        height: 60px;
    }

    .side-wrapper {
        margin-top: -50px;
    }

    #links-row p {
        font-size: 16px;
    }
    
    
}